import pluralize from 'pluralize'
import dayjs, { OpUnitType, QUnitType } from 'dayjs'
import { wrap, escape } from 'lodash'

import { DATE_UNITS_MAP, SERVER_DATE_FORMAT, SERVER_DATE_HOUR_FORMAT } from '@/helpers/constants'
import { removeExtraSpacesInString } from './string'

export function dateFormat(dateSrt: string, isIso: boolean = false): string {
  const date: Date = new Date(dateSrt)
  const dd: number = date.getDate()
  const mm: number = date.getMonth() + 1
  const yyyy: number = date.getFullYear()
  return isIso ? `${yyyy}-${mm > 9 ? mm : '0' + mm}-${dd > 9 ? dd : '0' + dd}` : `${dd > 9 ? dd : '0' + dd}.${mm > 9 ? mm : '0' + mm}.${yyyy}`
}

export function getDayFromToday(day = 0, dateType: string = 'Date', startOf: boolean = true): dayjs.Dayjs {
  const unit = DATE_UNITS_MAP[dateType]
  const dayFromToday = dayjs()[day >= 0 ? 'add' : 'subtract'](Math.abs(day), unit)

  return startOf ? dayFromToday.startOf(unit) : dayFromToday.endOf(unit)
}

export function secondsFormatter(sec: number, showFullTime: boolean = false): string {
  const dateTime = dayjs(sec * 1000).utc()

  const days: number = dateTime.date() - 1
  const hours: number = dateTime.hour()
  const minutes: number = dateTime.minute()
  const seconds: number = dateTime.second()

  const formattedDays: string = days > 0 ? `${days}d` : ''
  const formattedHours: string = hours > 0 ? `${hours}h` : ''
  const formattedMinutes: string = minutes > 0 ? `${minutes}m` : ''
  const formattedSeconds: string = seconds > 0 ? `${seconds}s` : ''
  const emptyFormattedDate: string = '0s'

  if (showFullTime) {
    return removeExtraSpacesInString(`${formattedDays} ${formattedHours} ${formattedMinutes} ${formattedSeconds}`) || emptyFormattedDate
  } else if (days > 0) {
    return `${formattedDays} ${formattedHours}`.trim()
  } else if (hours > 0) {
    return `${formattedHours} ${formattedMinutes}`.trim()
  } else if (minutes > 0) {
    return `${formattedMinutes} ${formattedSeconds}`.trim()
  } else if (sec >= 0 && sec <= 1.2) {
    return `${sec}s`
  }

  return formattedSeconds || emptyFormattedDate
}

export function getDateByDelta(date: string | number, delta: number | null, unit: QUnitType | OpUnitType = 'day') {
  const serverFormat = unit === 'hour' ? SERVER_DATE_HOUR_FORMAT : SERVER_DATE_FORMAT

  if (!delta) return dayjs(date).format(serverFormat)

  if (delta > 0) {
    return dayjs(date)
      .add(delta, unit as QUnitType)
      .format(serverFormat)
  }

  return dayjs(date)
    .subtract(Math.abs(delta), unit as QUnitType)
    .format(serverFormat)
}

export function getDateUnitByValueType(valueType: TValueType): QUnitType | OpUnitType {
  switch (valueType) {
    case 'DateHour':
      return 'hour'
    case 'Year':
      return 'year'
    case 'Quarter':
      return 'quarter'
    case 'Month':
      return 'month'
    case 'Week':
      return 'week'
    case 'Date':
    default:
      return 'day'
  }
}

export function getFormattedComparingDate(comparingDate: string, tooltipDelta: number, valueType: TDateType = 'Date'): string {
  if (valueType === 'Date') {
    if (tooltipDelta === -7) {
      return `prev ${dayjs(comparingDate).format('dddd')}`
    } else if (tooltipDelta === 7) {
      return `next ${dayjs(comparingDate).format('dddd')}`
    }
  }

  return dayUnitFormatter(comparingDate, valueType, false, valueType === 'Week')
}

export function getFormattedDurationTime(date: string, showHoursAndMinutes: boolean = false, isShort: boolean = false): string {
  const now = dayjs()
  const dateToCompare = dayjs(date).utc()

  const differenceInMinutes: number = now.diff(dateToCompare, 'minute')
  const minDifference: number = Math.floor(differenceInMinutes % 60)
  const hourDifference: number = Math.floor(differenceInMinutes / 60)
  const dayDifference: number = Math.floor(hourDifference / 24)
  const monthDifference: number = now.diff(dateToCompare, 'month')

  if (monthDifference > 0) {
    const timeUnitName = isShort ? 'Mo' : pluralize('month', monthDifference)
    return `${monthDifference} ${timeUnitName}`
  } else if (dayDifference > 0) {
    const timeUnitName = isShort ? 'd' : pluralize('day', dayDifference)
    return `${dayDifference} ${timeUnitName}`
  } else if (hourDifference > 0) {
    const hourUnitName = isShort ? 'h' : pluralize('hour', hourDifference)
    const minuteUnitName = isShort ? 'm' : pluralize('minute', minDifference)
    return showHoursAndMinutes || minDifference === 0
      ? `${hourDifference} ${hourUnitName}`
      : `${hourDifference} ${hourUnitName} ${minDifference} ${minuteUnitName}`
  }

  const minuteUnitName = isShort ? 'm' : pluralize('minute', minDifference)
  return `${minDifference} ${minuteUnitName}`
}

export function getDatesInRange(dateType: TDateType, minDate: string | number, maxDate: string | number): string[] {
  switch (dateType) {
    case 'DateHour':
      return getDateHourInRange(minDate, maxDate)
    case 'Year':
      return getYearsInRange(minDate, maxDate)
    case 'Quarter':
      return getQuartersInRange(minDate, maxDate)
    case 'Month':
      return getMonthsInRange(minDate, maxDate)
    case 'Week':
      return getWeeksInRange(minDate, maxDate)
    case 'Date':
    default:
      return getDaysInRange(minDate, maxDate)
  }
}

function getDaysInRange(minDate: string | number, maxDate: string | number): string[] {
  let currentDate = dayjs(minDate).startOf('day')
  const lastDate = maxDate ? dayjs(maxDate) : dayjs()
  const maxDaysCount = 730 // Max 2 years

  const daysRange = [currentDate.format(SERVER_DATE_FORMAT)]

  while (lastDate.isAfter(currentDate, 'day') && daysRange.length < maxDaysCount) {
    currentDate = currentDate.add(1, 'day')
    daysRange.push(currentDate.format(SERVER_DATE_FORMAT))
  }

  return daysRange
}

function getWeeksInRange(minDate: string | number, maxDate: string | number): string[] {
  let currentDate = dayjs(minDate).startOf('week')
  const lastDate = maxDate ? dayjs(maxDate).startOf('week') : dayjs().startOf('week')
  const maxWeeksCount = 104 // Max 2 years

  const weeksRange = [currentDate.format(SERVER_DATE_FORMAT)]

  while (lastDate.isAfter(currentDate, 'week') && weeksRange.length < maxWeeksCount) {
    currentDate = currentDate.add(1, 'week')
    weeksRange.push(currentDate.format(SERVER_DATE_FORMAT))
  }

  return weeksRange
}

function getMonthsInRange(minDate: string | number, maxDate: string | number): string[] {
  let currentDate = dayjs(minDate).startOf('month')
  const lastDate = maxDate ? dayjs(maxDate).startOf('month') : dayjs().startOf('month')
  const maxMonthsCount = 24 // Max 2 years

  const monthsRange = [currentDate.format(SERVER_DATE_FORMAT)]

  while (lastDate.isAfter(currentDate, 'month') && monthsRange.length < maxMonthsCount) {
    currentDate = currentDate.add(1, 'month')
    monthsRange.push(currentDate.format(SERVER_DATE_FORMAT))
  }

  return monthsRange
}

function getQuartersInRange(minDate: string | number, maxDate: string | number): string[] {
  let currentDate = dayjs(minDate).startOf('quarter')
  const lastDate = maxDate ? dayjs(maxDate).startOf('quarter') : dayjs().startOf('quarter')
  const maxQuartersCount = 8 // Max 2 years

  const quartersRange = [currentDate.format(SERVER_DATE_FORMAT)]

  while (lastDate.isAfter(currentDate, 'quarter') && quartersRange.length < maxQuartersCount) {
    currentDate = currentDate.add(1, 'quarter')
    quartersRange.push(currentDate.format(SERVER_DATE_FORMAT))
  }

  return quartersRange
}

function getYearsInRange(minDate: string | number, maxDate: string | number): string[] {
  let currentDate = dayjs(minDate).startOf('year')
  const lastDate = maxDate ? dayjs(maxDate).startOf('year') : dayjs().startOf('year')
  const maxYearsCount = 2 // Max 2 years

  const yearsRange = [currentDate.format(SERVER_DATE_FORMAT)]

  while (lastDate.isAfter(currentDate, 'year') && yearsRange.length < maxYearsCount) {
    currentDate = currentDate.add(1, 'year')
    yearsRange.push(currentDate.format(SERVER_DATE_FORMAT))
  }

  return yearsRange
}

function getDateHourInRange(minDate: string | number, maxDate: string | number): string[] {
  let currentDate = dayjs(minDate).startOf('day')
  const lastDate = maxDate && !dayjs(maxDate).isSame(dayjs(), 'day') ? dayjs(maxDate).endOf('day').subtract(1, 'hour') : dayjs()
  const maxRangeSize = 730 * 24 // Max 2 years

  const daysRange = [currentDate.format(SERVER_DATE_HOUR_FORMAT)]

  while (lastDate.isAfter(currentDate, 'hour') && daysRange.length < maxRangeSize) {
    currentDate = currentDate.add(1, 'hour')
    daysRange.push(currentDate.format(SERVER_DATE_HOUR_FORMAT))
  }

  return daysRange
}

export function dayUnitFormatter(date: string | number, dateType: TDateType, wrappedWithSpan: boolean = false, shortFormat: boolean = false): string {
  const wrapTemplate = wrap(escape, (func, text) => {
    return `<span class="wrapped-date">${text}</span>`
  })
  const valueTypeObj = {
    Date(): string {
      const formattedDate = dayjs(date).format(shortFormat ? 'MMM DD' : 'll')
      return wrappedWithSpan ? wrapTemplate(formattedDate) : formattedDate
    },
    Week(): string {
      const firstDayOfWeek = dayjs(date)
        .startOf('week')
        .format(shortFormat ? 'MMM DD' : 'MMMM DD')

      const lastDayOfWeek = dayjs(date)
        .endOf('week')
        .format(shortFormat ? 'MMM DD' : 'MMMM DD')

      return `${wrappedWithSpan ? wrapTemplate(firstDayOfWeek) : firstDayOfWeek} - ${wrappedWithSpan ? wrapTemplate(lastDayOfWeek) : lastDayOfWeek}`
    },
    Month(): string {
      const formattedMonth = dayjs(date).format(shortFormat ? 'MMM YYYY' : 'MMMM YYYY')
      return wrappedWithSpan ? wrapTemplate(formattedMonth) : formattedMonth
    },
    Quarter(): string {
      const firstMonthOfQuarter = dayjs(date)
        .startOf('quarter')
        .format(shortFormat ? 'MMM' : 'MMMM YYYY')

      const lastMonthOfQuarter = dayjs(date)
        .endOf('quarter')
        .format(shortFormat ? 'MMM' : 'MMMM YYYY')

      return `${wrappedWithSpan ? wrapTemplate(firstMonthOfQuarter) : firstMonthOfQuarter} - ${
        wrappedWithSpan ? wrapTemplate(lastMonthOfQuarter) : lastMonthOfQuarter
      }`
    },
    Year(): string {
      const formattedYear = dayjs(date).format('YYYY')
      return wrappedWithSpan ? wrapTemplate(formattedYear) : formattedYear
    },
    Hour(): string {
      const formattedDate = dayjs(date).format('H[h]')
      return wrappedWithSpan ? wrapTemplate(formattedDate) : formattedDate
    },
    DateHour(): string {
      const dateObj = dayjs(date)
      const formattedDate = `${dateObj.format('H[h]')}\n${dateObj.format('MMM D, YYYY')}`
      return wrappedWithSpan ? wrapTemplate(formattedDate) : formattedDate
    },
    DateHourMinute(): string {
      const dateObj = dayjs(date)
      const formattedDate = `${dateObj.format('MMM D YYYY')},\n${dateObj.format('H:mm')}`
      return wrappedWithSpan ? wrapTemplate(formattedDate) : formattedDate
    }
  }

  return valueTypeObj[dateType]()
}
